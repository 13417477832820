import * as History from 'history'
import { isArray, isNumber, last } from 'lodash-es'
import isEqual from 'fast-deep-equal'
import { createSelectorCreator, defaultMemoize } from 'reselect'
import { Dispatch, ReactNode } from 'react'
import { PaginationConfig } from 'antd/lib/pagination'
import moment from 'moment'
import { v1 as uuid } from 'uuid'
import { LicenseStatuses } from 'core/utils/constants'
import { DEFAULT_LIMIT } from 'core/constants'
import InputField from 'shared/components/fields/InputField'
import DatePickerField from 'shared/components/fields/date-picker/DatePickerField'
import InputNumberField from 'shared/components/fields/InputNumberField'
import { generateRandomId, getEntityNames, getValueByKeyChain } from 'shared/helpers/utils'
import { showNotification } from '../actions/Notifications.actions'
import ActionsList from '../actions/Actions.list'
import { ISeatType, NotificationTypes } from 'reducers/Reducers.interfaces'
import { InputTypes } from 'configs/Enrollment.config'
import { INavigationItem } from 'configs/Navigation.config'
import historyObject from '../history/history'
import { TypeInputList } from 'public-pages/enrollment/Enrollment.interfaces'
import { ActionType, IFormValue } from './interfaces'

export const pipeValidators = (...fns): any =>
  (...currentArg): any =>
    fns.reduce((accumulator, f): any => accumulator || f(...currentArg), false)

export function getterHash (location: History.Location): number {
  return location.hash ? Number(location.hash.slice(1)) : 1
}

export function goNextStepHandler (step: number, location: History.Location): () => void {
  return (): void => {
    historyObject.push({
      pathname: location.pathname,
      hash: `${step + 1}`
    })
  }
}

export const errorNotifier = (dispatch, error, type: ActionsList, translateMessage = false): void => {
  dispatch({ type })
  dispatch(showNotification({
    id: generateRandomId(),
    title: `notifications.exceptions.${error.status}`,
    message: error.message,
    type: NotificationTypes.error,
    translateMessage: translateMessage
  }))
}

export const checkFailedRequests = (dispatch, actionType, ...responses): any => {
  let hasFailedRequests = false
  responses.forEach((response): any => {
    if (response.status !== 200) {
      hasFailedRequests = true
      errorNotifier(dispatch, response.data.error, actionType)
    }
  })
  return hasFailedRequests
}

export function checkIsNumberHash (hash: number): number {
  return isNumber(hash) ? hash : 1
}

export const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  isEqual
)

export const sortSeatTypeComparator = ([seatType]: [string, ISeatType], [secondSeatType]: [string, ISeatType]): number => {
  if (seatType < secondSeatType) {
    return 1
  }
  if (seatType > secondSeatType) {
    return -1
  }
  return 0
}

export const getComponentByType = (type: InputTypes = InputTypes.text): ReactNode => {
  switch (type) {
    case InputTypes.text:
      return InputField
    case InputTypes.date:
      return DatePickerField
    case InputTypes.number:
      return InputNumberField
    default:
      return InputField
  }
}

export const getInputProfileTypeForConfig = (input: TypeInputList): InputTypes => {
  switch (input) {
    case 'text':
      return InputTypes.text
    case 'number':
      return InputTypes.number
    case 'dropdown':
      return InputTypes.select
    default:
      return InputTypes.text
  }
}

export const isBackRedirect = (location: History.Location): boolean => {
  const hash = getterHash(location)
  const isActionUrl = location.pathname.includes('create') || location.pathname.includes('edit') || location.pathname.includes('enroll')
  return isActionUrl && hash === 1
}

export const getRedirectedTableUrl = (location: History.Location): string => {
  const url = location.pathname
  const slicesUrls = location.pathname.split('/')
  const createShift = -1
  const editShift = -2
  const numberShift = url.includes('create') ? createShift : editShift
  return slicesUrls.slice(0, numberShift).join('/')
}

export function goBack (): void {
  if (isBackRedirect(historyObject.location)) {
    historyObject.push(getRedirectedTableUrl(historyObject.location))
  } else {
    historyObject.goBack()
  }
}

export const onMissingTranslation = ({ translationId }): string => {
  const lastString = last(translationId.split('.')) as string
  return lastString || ''
}

export const parseValueToSelectValue = (value: IFormValue): any =>
  ({ key: value.id, label: value.name })

export const isActiveLicense = (license: string, navigation: INavigationItem): boolean =>
  !(!navigation.isShowExpiredLicense && license === LicenseStatuses.expired)

export function filterByColumn (
  value: string,
  record: any,
  dataIndex: string | string[],
  parseFunc?: any
): boolean {
  let source: any =
      dataIndex instanceof Array
        ? getValueByKeyChain(record, dataIndex)
        : record[dataIndex]

  if (source === undefined) return false

  if (source instanceof Array) {
    source = parseFunc ? parseFunc(source) : getEntityNames(source)
  } else if (parseFunc) {
    source = parseFunc(source)
  }

  return source
    .toString()
    .toLowerCase()
    .includes(value.toLowerCase())
}

export function mapServerMetaDataToTable<G = any> (
  data: any
): any {
  return {
    pagination: {
      current: data.offset / (data.limit || DEFAULT_LIMIT) + 1,
      total: data.total,
      pageSize: data.limit || DEFAULT_LIMIT
    }
  }
}

export const mapPaginationToParam = ({
  pageSize = DEFAULT_LIMIT,
  current = 1
}: PaginationConfig): string => {
  return `?limit=${pageSize}&offset=${(current - 1) * pageSize}`
}

export const parseDateToServer = (date: string): string => moment.utc(date).format()

export function actionErrorNotifier (
  apiCore: (dispatch: Dispatch<any>) => Promise<any>,
  showOnSuccess = false,
  { title, message } = { title: '', message: '' }
): ActionType {
  return async function (dispatch: Dispatch<any>): Promise<any> {
    try {
      const response = await apiCore(dispatch)
      if (showOnSuccess) {
        dispatch(showNotification({
          id: uuid(),
          title,
          message,
          type: NotificationTypes.success
        }))
      }
      return response
    } catch (e: any) {
      try {
        const errors = JSON.parse(e.message)
        if (isArray(errors)) {
          errors.forEach(({ error }): any => {
              dispatch(showNotification({
                  id: uuid(),
                  title: title || undefined,
                  type: NotificationTypes.error,
                  message: `${error.message}`,
                  translateMessage: true
                })
              )
            }
          )
        }
        if (isArray(errors.errors)) {
          errors.errors.forEach(error => {
            dispatch(showNotification({
              id: uuid(),
              title: title || undefined,
              type: NotificationTypes.error,
              message: `${error.message}`,
              translateMessage: true
            }))
          }
          )
        }
      } catch (err: any) {
       if(e.message && !e.message.startsWith('Cannot read property') && !e.message.includes('undefined')) {
        dispatch(showNotification({
          id: uuid(),
          title: title || undefined,
          type: NotificationTypes.error,
          message: `${e.message}`,
          translateMessage: true
        }))
        }
      }
    }
  }
}

export function asyncActionIterator (...actions: ActionType[]): ActionType {
  let previousResult = null
  return async function (dispatch: Dispatch<any>): Promise<void> {
    for await (const action of actions) {
      previousResult = await action(dispatch, previousResult)
    }
  }
}

export const mapResponseToReducerAsToObjectInArray = (id: string): any =>
  (data: any): any => ({ data, id })

