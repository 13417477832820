import React, { ChangeEvent, ReactElement, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IAppState } from '../../../core/store/store'
import { TenantEditProps } from '../Tenants.types'
import { withPermissionsHOC } from '../../../core/hoc/permissionsHoc'
import { editTenant, fetchTenantById } from '../Tenants.actions'
import { IAccessScope, ITenant } from '../../../reducers/Reducers.interfaces'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import {
  Button,
  ButtonType,
  DatePicker,
  Form,
  FormFieldSize,
  FormGroup,
  Input,
  PageLevelBanner,
  PageLevelBannerType,
  Select,
  SubmitButton
} from '@digicert/dcone-common-ui'
import History from '../../../core/history/history'
import { accountUrl } from '../../../configs/Path.configs'
import moment, { Moment } from 'moment';
import { translate } from '../../../shared/helpers/utils'
import { Translate } from 'react-localize-redux'
import NoPermissionsPage from '../../../shared/components/no-permissions-page'
import { NAME_REGEXP,ACCOUNT_NAME_REGEXP, RouterConfig } from 'core/utils/constants'
import { faExternalLinkSquare } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const EditTenant = (props:TenantEditProps): ReactElement => {
  const dispatch: ThunkDispatch<IAppState, any, AnyAction> = useDispatch()
  const [tenant, setTenant] = useState<ITenant>()
  const [name, setName] = useState('')
  const [exactDate, setExactDate] = useState<Moment | null>(null)
  // set and update locales to support Select OptionProps
  const locales = useSelector((state: IAppState) => state.locales.locales.map(item => ({
    value: item.id,
    label: item.name
  })))
  const accessScope = useSelector((state: IAppState) => state.profile.data?.access_scope)
  const profile = useSelector((state: IAppState) => state.profile.data)
  const [selection, setSelection] = useState('')
  const validate = () => (!selection ? translate('form.validation.invalidSelect') : '')
  const [nameMaxLengthError, setNameMaxLengthError] = useState(false)
  const [nameDuplicateError, setNameDuplicateError] = useState(false)
  const [oneloginAccount, setOneloginAccount] = useState(false)
  const handleExactDateChange = useCallback(
    (value) => {
      setExactDate(value);
    },
    [setExactDate]
  );

  const disabledDate = useCallback((current) => {
    return current && current < moment().endOf('day');
  }, []);

  const validateExactDate = useCallback(() => {
    if (exactDate === null) return translate('common.form.validation.required')
  }, [exactDate]);

  const handleSelect = (value): void => {
    setSelection(value)
  }

  const changeName = (event: ChangeEvent<HTMLInputElement>) => {
    if (event) {
      const accountName = event.target.value
      setName(accountName)
        if (accountName.length > 255) {
          setNameMaxLengthError(true)
        } else {
          setNameMaxLengthError(false)
        }
    }

  }

  const nameValidity = useCallback(() => {
    if (nameMaxLengthError) {
      return translate('notifications.errors.maxLengthError') as string
    }
  }, [nameMaxLengthError])

  const onSubmit = (e) => {
    e.preventDefault()
    if (tenant) {
      const postData = {
        name: name,
        locale: selection,
        'service_period': {
          from: tenant.service_period.from,
          to: exactDate
        }
      }
      dispatch(editTenant(tenant.id, postData, accessScope))
    }
  }

  useEffect(() => {
    dispatch(fetchTenantById(props.match.params.id)).then((res: any) => {
      if (res) {
        setTenant(res)
        setOneloginAccount(res.has_onelogin_id)
        setExactDate(moment(res.service_period.to))
        setName(res.name)
        setSelection(locales.filter(item => item.value === res.locale)[0].value)
      }
    })
  },[
    dispatch,
    props.match.params.id,
    setTenant,
    setExactDate,
    setName,
    setSelection
  ])

  if (accessScope === IAccessScope.account) {
    return (
      <NoPermissionsPage />
    )
  }

  else return (
    <>
      {tenant && (
        <section>
          <h1>{oneloginAccount ? <Translate id='breadcrumb.details.account' /> : <Translate id='accounts.editAccount'/>}</h1>
          <br/>
          {oneloginAccount &&
            <PageLevelBanner
            bannerType={PageLevelBannerType.INFO}
            description={
              <>
              <Translate id='createAccount.olEditAccountWarning'/>
                 <a target="_blank" rel="noreferrer" href={profile?.onelogin_url + RouterConfig.oneloginAccount.editOneloginAccount(tenant.federated_account_id)}>
                  <Translate id='createAccount.olAccount'/>
                  <FontAwesomeIcon size='lg' icon={faExternalLinkSquare} />
                </a>
              </>
            }
          />
          }
          <Form
            center={false}
            onSubmit={(e => {onSubmit(e)})}
          >
            <FormGroup>
            <Input
              label={translate('createAccount.accountName')}
              size={FormFieldSize.M}
              required
              minLength={1}
              maxLength={256}
              value={name}
              disabled={oneloginAccount}
              onChange={changeName}
              pattern={ACCOUNT_NAME_REGEXP}
              errors={{
                valueMissing: translate('common.form.validation.required'),
                patternMismatch: translate('notifications.errors.invalidAccountNameError')
              }}
              id='account_name'
              validate={nameValidity}
            />
            <Input
              size={FormFieldSize.M}
              value={tenant.friendly_identifier}
              disabled
              label={translate('accounts.form.friendlyIdentifier')}
            />
          </FormGroup>

            <FormGroup>
              <DatePicker
                label={translate('accounts.form.serviceDateTo')}
                onChange={handleExactDateChange}
                disabledDate={disabledDate}
                validate={validateExactDate}
                value={exactDate}
                disabled = {profile.access_scope === IAccessScope.partner && profile.primary_account_id == tenant.id}
              />
            </FormGroup>

            <FormGroup>
              <Select
                getPopupContainer={triggerNode => triggerNode.parentElement}
                validate={validate}
                defaultValue={selection}
                value={selection}
                onChange={handleSelect}
                options={locales}
                label={translate('profile.language')}
              />
            </FormGroup>

            <FormGroup colWidth={FormFieldSize.S}>
              <Button buttonType={ButtonType.SECONDARY} onClick={() => {
                History.push(`${accountUrl}`)
              }}>
                <Translate id='common.form.buttons.cancelBtn'/>
              </Button>
              <SubmitButton>
                <Translate id='common.form.buttons.updateBtn' />
              </SubmitButton>
              
            </FormGroup>
          </Form>
        </section>
      )}
    </>
  )
}

export default withPermissionsHOC(EditTenant, ['canManageAccount'])