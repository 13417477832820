import { RouteComponentProps } from 'react-router'
import { ReactElement } from 'react'
import { IPermissions } from 'core/selectors/Profile.selectors'
import { IActionCreatorAsync, IFilters } from 'core/actions/Actions.interfaces'
import ActionsList from 'core/actions/Actions.list'
import { FormMode, IMatchParams } from 'shared/interfaces'
import { ISeatTypeField } from 'shared/components/seat-allocations/seat-allocations.interfaces'
import {
  IAccessScope,
  IAccount as account,
  ILocales,
  ISeatType,
  ITemplate,
  ITenant,
  ITenantSeatTypes,
  IUser,
  IFeatureFlags
} from 'reducers/Reducers.interfaces'
import TenantsConstants from './Tenants.constants'
import { TableMetaProps } from '@digicert/dcone-common-ui'

interface IOwnProps {
  permissions: IPermissions
}

export interface IStateProps {
  tenants: ITenant[]
  isLoading: boolean
  accessScope: IAccessScope | undefined
  profileTenants: account[] | undefined
  profile?: IUser
  features: IFeatureFlags
}

interface IDispatchProps {
  fetchTenants: IActionCreatorAsync
  deleteTenants: IActionCreatorAsync
  fetchProfile: IActionCreatorAsync
  fetchExpiringAccounts: IActionCreatorAsync
}

export type PropsType = IOwnProps &
  IStateProps &
  IDispatchProps &
  RouteComponentProps

export interface IState {
  meta: TableMetaProps<ITenant>
  searchText: string
  current: number
  offset: number
  sortedInfo: {
    order?: 'descend' | 'ascend'
    columnKey?: string
  }
  isDeleteModalVisible: boolean
  isDeleting: boolean
  tenantIds: string[]
  filters?: IFilters,
  showPageLevelWarning: boolean
  showPageLevelError: boolean
  warningMessage: string
  errorMessage: string
  showErrorBannerAfterDismiss: boolean
  showWarnBannerAfterDismiss: boolean
  loading?: boolean
}

export interface IAction {
  type: TenantsConstants | ActionsList
  payload?: any
}

export interface ITenantPropsState {
  isCreating: boolean
  fromValue: string
  isLoading: boolean
  isEditing: boolean
  accessScope: IAccessScope
  tenant?: ITenant
  seatTypesFields: ISeatTypeField[]
  allTemplates: ITemplate[]
  allAccounts: account[]
  account: account
  allSeatsTypes: ISeatType[]
  dateFormat?: string
  localeList?: ILocales
}

export interface ITenantPropsActions {
  createTenantWithNewAccount: IActionCreatorAsync
  fetchTenantById: IActionCreatorAsync
  editTenant: IActionCreatorAsync
  destroyForm: IActionCreatorAsync
  fetchProfile: IActionCreatorAsync
  fetchTemplates: IActionCreatorAsync
  initialize: IActionCreatorAsync
  fetchSeatTypes: IActionCreatorAsync
}

export type TenantCreateProps = Pick<
  ITenantPropsState,
  'isCreating' | 'fromValue' | 'seatTypesFields' | 'accessScope'
> &
  Pick<
    ITenantPropsActions,
    | 'createTenantWithNewAccount'
    | 'destroyForm'
    | 'fetchProfile'
    | 'fetchTemplates'
    | 'initialize'
    | 'fetchSeatTypes'
  > &
  IPermissions &
  RouteComponentProps

export type TenantEditProps = Pick<
  ITenantPropsState,
  | 'isLoading'
  | 'fromValue'
  | 'isEditing'
  | 'tenant'
  | 'accessScope'
  | 'allAccounts'
  | 'account'
  | 'dateFormat'
  | 'localeList'
> &
  Pick<
    ITenantPropsActions,
    'fetchTenantById' | 'editTenant' | 'destroyForm' | 'fetchProfile'
  > &
  IPermissions &
  RouteComponentProps<IMatchParams>

export interface ITenantFormProps {
  mode: FormMode
  onSubmit?: (formData: any) => void
  onComplete?: () => void
  initialValues: any
  accountName?: string
  servicePeriod?: IServicePeriod
}

export interface IServicePeriod {
  from: string
  to: string
}

export interface ITenantFormState {
  step: number
  lastPage: number
}

export interface ITenantWrapperOwnProps {
  onCancel?: () => void
  children: ReactElement
  skipCancel?: boolean
  goBack?: () => void
}

export type TenantFormProps = ITenantFormProps &
  Pick<RouteComponentProps, 'location'> &
  Pick<ITenantPropsState, 'isLoading' | 'fromValue' | 'dateFormat' | 'localeList'> & {
    currentLink: string
    hash: string
  }

export type TenantFormWrapperProps = ITenantFormProps &
  ITenantFormState &
  ITenantWrapperOwnProps &
  Pick<TenantFormProps, 'isLoading'>

export type TenantsFormGeneralInformationProps = Pick<
  TenantFormProps,
  'mode' | 'fromValue' | 'dateFormat' | 'localeList'
>

export type TenantMapWithSeatTypes = Record<string, ITenantSeatTypes>

export interface LicenseFormData {
  type: string
  value: number
}

export enum AccountTypes {
  STANDARD = 'standard',
  PARTNER = 'partner'
}

