import { Store, createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { LocalizeState } from 'react-localize-redux'
import thunk, { ThunkMiddleware } from 'redux-thunk'

import reducers from '../../reducers/AppReducers'
import {
  IAuth,
  IApiTokens,
  IClientAuthCerts,
  IAdminProfile,
  IUsers,
  IAccounts,
  IOrganizations,
  IContacts,
  INotifications,
  ITenants,
  IEnrollment,
  ICertificateAuthorities,
  ITemplates,
  IProfiles,
  IFederation,
  ISigningProviders,
  ILocales,
  IAdminUserProfile,
  ILicenseData,
  IFeatureFlags,
  IUserApplication
} from 'reducers/Reducers.interfaces'
import { IAction } from '../actions/Actions.interfaces'
import { ISwitchersTemplateState } from 'shared/components/switchers-template/switchers-template.interface'
import apiMiddleware from 'core/utils/middleware'
import { ICertificateObtaining } from 'public-pages/certificate/interfaces'
import {IAuditLogsEventState} from '../../secure-pages/reporting/audit-logs/AuditLogs.types'

export interface IAppState {
  auth: IAuth
  apiTokens: IApiTokens
  clientAuthCerts: IClientAuthCerts
  profile: IAdminProfile
  userProfile: IAdminUserProfile
  userApplications: IUserApplication
  users: IUsers
  accounts: IAccounts
  tenants: ITenants
  federation: IFederation
  organizations: IOrganizations
  contacts: IContacts
  notifications: INotifications
  locales: ILocales,
  license: ILicenseData,
  features: IFeatureFlags,
  enrollment: IEnrollment,
  rootCA: ICertificateAuthorities,
  issuingCA: ICertificateAuthorities,
  tenantTemplates: ISwitchersTemplateState,
  templates: ITemplates,
  localize: LocalizeState
  profiles: IProfiles
  auditEvents: IAuditLogsEventState,
  accountSigningClient: ISigningProviders
  certificateObtaining: ICertificateObtaining
}

const store: Store<IAppState> = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(thunk as ThunkMiddleware<IAppState, IAction>, apiMiddleware))
)

export default store
