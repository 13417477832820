import {
  BasePathConst,
  imauthLoginUrl,
  imauthLogoutUrl,
  documentManager
} from 'configs/Path.configs'
import { CertificateStatuses } from 'core/constants'
import { RouterRest } from 'core/utils/interfaces'

export const LIMIT_MAX = 99999
export const LIMIT_20 = 20
export const LIMIT_10 = 10
export const LIMIT_5 = 5
export const ALPHANUMERIC_WITH_DASH_DOT = "^[\\w \\-.]*$"
export const ALPHA_DIACRITIC_REGEXP = "^[\\w \\-().,&'#/~; \\p{L}+|\\p{M}+]*$"
export const CITY_REGEXP = "^[\\w \\-().,&'#/~; \\p{L}+|\\p{M}+]*$"
export const ADDRESS_REGEXP = "^[\\w \\-\\(\\).,'#&\\/~;: \\p{L}+\\|\\p{M}+]*$"
export const NAME_REGEXP = "^[\\w \\-\\(\\).,&'#\\/~;!\" \\p{L}+\\|\\p{M}+]*$"
export const ACCOUNT_NAME_REGEXP = "^[\\w \\-\\(\\).,&'#\\/~;!@\" \\p{L}+\\|\\p{M}+]*$"
export const USER_NAME_REGEXP = "^[\\w\\-_!#$%&'*+\\/`=?@.,\\p{L}+\\|\\p{M}+]*$"
export const EMAIL_REGEXP = "^[a-zA-Z0-9_!#$%&'*+\\/`=?\\{\\|\\}~^.\\-]+@[a-zA-Z0-9.\\-]+.[a-zA-Z]{2,63}$"
export const PASSWORD_REGEX = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!-/:-@[-`{-~]).{12,255}$'
export const NUMBERS_ONLY_REGEX = '^(?=.*?[0-9]).{6,}$'
export const MIN_PASSWORD_CHARS = 12
export const MAX_PASSWORD_CHARS = 255
export const MIN_OTP_CHARS = 6

export const defaultListParams = {
  limit: LIMIT_20,
  offset: 0
}

export const uploadFileOptions = {
  FILE_FORMAT: 'application/x-x509-ca-cert',
  FILE_SIZE_LIMIT: 4
}

export const maxListParams = {
  limit: LIMIT_MAX,
  offset: 0
}

export enum RulesSourceOptions {
  userSupplies = 'user_supplied',
  fixedValue = 'fixed_value'
}

export const ID_SECURE_LAYOUT = 'secure_layout_page'

export const ymdFormat = 'YYYY-MM-DD'

export const API = 'API_ACTION'

export const HTTP_SUCCESS_STATUSES = {
  GET: 200,
  PUT: [200, 204],
  POST: [201, 204],
  DELETE: 204
}

export enum CAPageTypes {
  rootCA = 'rootCA',
  issuingCA = 'issuingCA'
}

export enum CertTypes {
  root = 'root',
  intermediate = 'intermediate'
}

export enum CANavigationLinks {
  roots = 'roots',
  intermediates = 'ica'
}

export const statusesColorMap = {
  Enabled: 'green',
  Active: 'green',
  Success: 'green',
  Suspended: 'gold',
  Disabled: 'red',
  Locked: 'volcano',
  Error: 'red',
  Deleted: 'red',
  Pending: 'geekblue',
  Approved: 'green',
  Rejected: 'red',
  Created: 'gold',
  Expired: 'volcano',
  Redeemed: 'purple',
  Valid: 'green',
 Pending_enabled: 'green'
}

export const statusesCertificateColorMap = {
  [CertificateStatuses.ISSUED]: 'green',
  [CertificateStatuses.REVOKED]: 'red',
  [CertificateStatuses.EXPIRED]: 'gold'
}

class RouterUrlMapper {
  public static id = 'id'
  public urlMap = {
    login: `${imauthLoginUrl}?returnPath=/account/accounts`,
    logout: `${imauthLogoutUrl}`,
    setupUser: `${BasePathConst}/setup-user`,
    adminProfile: `${BasePathConst}/profile`,
    editAdminProfile: `${BasePathConst}/profile/edit`,
    access: `${BasePathConst}/access`,
    administrators: `${BasePathConst}/access/administrators`,
    oneloginUser: `/access/administrators`,
    oneloginAccount: `/accounts`,
    accounts: `${BasePathConst}/accounts`,
    businessUnits: `${BasePathConst}/business-units`,
    profiles: `${BasePathConst}/profiles`,
    enrollments: `${BasePathConst}/enrollments`,
    webEnrollment: `${BasePathConst}/web-enrollment`,
    certificates: `${BasePathConst}/certificates`,
    setupLicense: `${BasePathConst}/setup-license`,
    integrationGuides: `${BasePathConst}/integration-guides`,
    ca: `${process.env.REACT_APP_CA_PATH}/ca`,
    apiTokens: `${BasePathConst}/access/api-tokens`,
    clientAuth: `${BasePathConst}/access/client-auth`,
    reporting: `${BasePathConst}/reporting`,
    logging: `${BasePathConst}/reporting/logging`,
    settings: `${BasePathConst}/settings`,
    roles: `${BasePathConst}/access/manage-user-roles`,
    apiDocs: `${BasePathConst}/docs/swagger-ui/index.html`,
    clientTools: `${BasePathConst}/client-tools`,
    organizations: `${BasePathConst}/ca/organizations`,
    samlVerificationError: `${BasePathConst}/samlVerificationError/:errorCode?`,
    clientAuthLoginVerificationError: `${BasePathConst}/clientAuthLoginVerificationError/:errorCode?/:errorMessage?`,
    agreement: `${BasePathConst}/agreement/:userId`,
    serviceUser: `${BasePathConst}/access/service-user`,
    updateLicenseError: `${BasePathConst}/updateLicenseError`,
    accountExpiredError: `${BasePathConst}/accountExpiredError`,
    certificatePickup: `${BasePathConst}/certificate-pickup`,
    documentManager: `${documentManager}/dashboard`,
    welcome: `${BasePathConst}/welcome`,
    userCreationViaSSO: 'https://docs.digicert.com/en/digicert-one/account-manager/users-and-access/user-creation-via-sso.html',
    dtmLicensingAndPlans: 'https://docs.digicert.com/en/device-trust-manager/licensing-and-plans.html'
  }

  public get administrators(): RouterRest {
    return this.buildRestUrls(this.urlMap.administrators)
  }

  public get oneloginUser() : RouterRest {
    return this.buildRestUrls(this.urlMap.oneloginUser)
  }

  public get oneloginAccount() : RouterRest {
    return this.buildRestUrls(this.urlMap.oneloginAccount)
  }

  public get businessUnits(): RouterRest {
    return this.buildRestUrls(this.urlMap.businessUnits)
  }

  public get accounts(): RouterRest & {
    createCustomer: string
    createAccountSignIn: any
    editStandardSignIn: any
    editMfa: any
    updateClientAuthCertLogin: any
    createAccountSigningClient: any
    editAccountSigningClient: any
    createAccount: any
    createPartnerAccount: any
    openIdIntegration: any
    addOauth: any
    editOauth: any
  } {
    return {
      ...this.buildRestUrls(this.urlMap.accounts),
      createCustomer: `${this.urlMap.accounts}/create-customer`,
      createAccountSignIn: (id: string = ':id'): string =>
        `${this.urlMap.accounts}/${id}/createAccountSignIn`,
      editStandardSignIn: (id: string = ':id'): string =>
        `${this.urlMap.accounts}/${id}/editStandardSignIn`,
      editMfa: (id: string = ':id'): string =>
        `${this.urlMap.accounts}/${id}/editMfa`,
      updateClientAuthCertLogin: (id: string = ':id'): string =>
      `${this.urlMap.accounts}/${id}/updateClientAuthCertLogin`,
      createAccountSigningClient: (id: string = ':id'): string =>
        `${this.urlMap.accounts}/${id}/createAccountSigningClient`,
      editAccountSigningClient: (
        id: string = ':id',
        configId: string = ':configId'
      ): string =>
        `${this.urlMap.accounts}/${id}/editAccountSigningClient/${configId}`,
      createAccount: `${this.urlMap.accounts}/create-account`,
      createPartnerAccount: `${this.urlMap.accounts}/create-partner-account`,
      openIdIntegration: (id: string = ':id'): string => `${this.urlMap.accounts}/${id}/updateOpenidConnectIntegration`,
      addOauth: (id: string = ':id'): string => `${this.urlMap.accounts}/${id}/add-oauth-integration`,
      editOauth:  (id: string = ':id',configId: string = ':configId'): string => `${this.urlMap.accounts}/${id}/edit-oauth-integration/${configId}`
    }
  }

  public get serviceUser() {
    return {
      ...this.buildRestUrls(this.urlMap.serviceUser)
    }
  }

  public get organizations(): Record<string, any> {
    const baseRoutes: RouterRest = this.buildRestUrls(this.urlMap.organizations)
    return {
      ...baseRoutes,
      createContact: (id: string = ':id'): string => `${baseRoutes.details(id)}/create-contact`,
      editContact: (
        organizationId: string = ':organizationId',
        contactId: string = ':contactId'
      ): string => `${baseRoutes.table}/${organizationId}/${contactId}`
    }
  }

  public get platformSettings(): any {
    const baseRoutes = this.urlMap.settings
    return {
      settings: baseRoutes,
      addOauth: (): string => `${baseRoutes}/add-oauth-integration`,
      editOauth: (id: string = ':id'): string => `${baseRoutes}/edit-oauth-integration/${id}`,
      updateLicense: `${baseRoutes}/settings/update-license`,
      updateStandardSignIn: (id: string = ':id'): string => `${this.urlMap.settings}/${id}/editStandardSignIn`,
      addAccountSignIn: (id: string = ':id'): string => `${this.urlMap.settings}/${id}/createAccountSignIn`,
      updateMfa: (id: string = ':id'): string => `${this.urlMap.settings}/${id}/editMfa`,
      settingsOpenIdIntegration: (id: string = ':id'): string => `${this.urlMap.settings}/${id}/updateOpenidConnectIntegration`
    }
  }

  public get manageUserRoles(): any {
    const baseRoutes = this.urlMap.roles
    return {
      roles: baseRoutes,
      create: (): string => `${baseRoutes}/create-role`,
      edit: (id: string = ':id'): string => `${baseRoutes}/edit-role/${id}`,
      detail: (id: string = ':id'): string => `${baseRoutes}/role-detail/${id}`,
      clone: (id: string = ':id'): string => `${baseRoutes}/clone-role/${id}`
    }
  }
  
  public get apiDocumentation(): any {
    const baseRoutes = this.urlMap.apiDocs
    return {
      apiDocs: baseRoutes
    }
  }

  public get profiles(): RouterRest {
    return this.buildRestUrls(this.urlMap.profiles)
  }

  public get logging(): RouterRest {
    return this.buildRestUrls(this.urlMap.logging)
  }

  public get ca(): Record<string, any> {
    const ca = this.urlMap.ca
    const tableRoots = `${ca}/root`
    const ica = `${ca}/intermediate`

    return {
      dashboard: ca,
      tableRoots: tableRoots,
      detailsRoots(id: string = ':id'): string {
        return `${tableRoots}/${id}`
      },
      tableIca: ica,
      detailsIca(id: string = ':id'): string {
        return `${ica}/${id}`
      }
    }
  }

  public get apiTokens(): any {
    const table = this.urlMap.apiTokens
    return {
      table,
      create: `${table}/create`,
      edit: (id: string = ':id'): string => `${table}/${id}/edit`
    }
  }

  public get clientAuth(): any {
    const table = this.urlMap.clientAuth
    return {
      table,
      create: (id: string = ':id') : string => `${table}/user/${id}/create`
    }
  }

  private buildRestUrls(name: string): RouterRest {
    return {
      table: name,
      create: `${name}/create`,
      rolesEdit: (id: string = ':id'): string => `${name}/${id}/role-user-edit`,
      editRoles: (id: string = ':id'): string => `${name}/${id}/role-edit`,
      createUserWithRole: `${name}/role-user-create`,
      createServiceUserWithRole:`${name}/role-service-user-create`,
      editServiceUserWithRole: (id: string = ':id'): string => `${name}/${id}/role-service-user-edit`,
      bulkCreate: `${name}/bulk-user-create`,
      bulkCreateWithRole: `${name}/role-bulk-user-create`,
      edit: (id: string = ':id'): string => `${name}/${id}/edit`,
      details: (id: string = ':id'): string => `${name}/${id}`,
      createAccountSignIn: (id: string = ':id'): string =>
        `${name}/${id}/createAccountSignIn`,
      apiToken: (id: string = ':id'): string =>
        `${name}/${id}/create-api-Token`,  
      apiTokenEdit: (id: string = ':id', tokenId: string = ':tokenId'): string =>
        `${name}/${id}/edit-api-Token/${tokenId}`,
      clientAuth: (id: string = ':id'): string =>
        `${name}/${id}/client-auth`,
      uploadCert:   (id: string = ':id'): string =>
        `${name}/${id}/upload-certificate`,
      editOneloginUser: (id: string = ':id'): string => 
        `${name}/${id}/user-edit`,
      editOneloginAccount: (id: string = ':id'): string =>
        `${name}/${id}/edit`,
    }
  }
}

export const RouterConfig = new RouterUrlMapper()

export const LICENSE_TIMEOUT_REQUEST = 60000

export enum LicenseStatuses {
  expired = 'EXPIRED',
  notFound = 'NOT_FOUND',
  valid = 'VALID'
}

export enum ActiveSection {
  AccountManager = 'accountManager',
  EnterpriseManager = 'enterpriseManager',
  CAManager = 'caManager',
  DeviceManager = 'deviceManager'
}

export const DCONE_ACCESS_TOKEN_COOKIE_NAME = 'dcone.access.token'
export const DCONE_REFRESH_TOKEN_COOKIE_NAME = 'dcone.refresh.token'
export const ACCOUNT_EXPIRY_NOTIFICATION = 'accountExpiryNotification'
export const PAGE_LEVEL_WARN_NOTIFICATION =  'pageLevelWarnNotification'
export const PAGE_LEVEL_ERROR_NOTIFICATION =  'pageLevelErrorNotification'
export const DCONE_LOCALE_COOKIE_NAME = 'lang'
export const REQUEST = 'REQUEST'
export const FAILURE = 'FAILURE'
export const SUCCESS = 'SUCCESS'
export const END = 'END'
export const PATTERN_SEPARATOR = '_'

export const reg = new RegExp('^[a-zA-Z0-9#!$_.-]*$', '')
export const urlRegExpression = new RegExp('[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)')

export const DTM_URL = 'https://www.digicert.com/document-trust-manager'
export const TLM_URL = 'https://www.digicert.com/trust-lifecycle-manager'
export const IOT_URL = 'https://www.digicert.com/iot-trust-manager'
export const STM_URL = 'https://www.digicert.com/software-trust-manager'
export const CONTACT_US_URL = 'https://www.digicert.com/contact-us'