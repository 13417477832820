import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { Translate } from 'react-localize-redux'
import styles from './Features.module.scss'
import { IFeatureEnabledType } from './Features.types'
import cn from 'classnames'
import { Button, ButtonType, DatePicker, Switch, Tag, TagType } from '@digicert/dcone-common-ui'
import { faExclamationCircle, faPencil } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'
import { enableFeature } from './Features.actions'
import { translate } from 'shared/helpers/utils'
import moment, { Moment } from 'moment'
import { AccountFeatures } from 'core/constants'
import { Applications } from '../Tenant.types'
import { IAccessScope } from 'reducers/Reducers.interfaces'

const Features = (props: IFeatureEnabledType): ReactElement => {
  const [editing, setEditing] = useState(false)
  const [appBeingEdited, setAppBeingEdited] = useState('')
  const [appCodeBeingEdited, setAppCodeBeingEdited] = useState('')
  const [, setContext] = useState('')
  const [featureEnabledList, setFeatureEnabledList] = useState<Array<any>>()
  const [errorState, setErrorState] = useState(false)
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [date, setDate] = useState<Moment>()
  const [disableSave, setDisableSave] = useState(true)
  const [keyclockerEnabled, setKeylockerEnabled] = useState(false)
  const [certIssuanceEnabled, setCertIssuanceEnabled] = useState(false)
  const [enableAllSelected, setEnableAllSelected] = useState(false)
  const [switchEnableList, setSwitchEnableList] = useState<Array<any>>()

  useEffect(() => {
    if (editing) {
      props.features.map(feat => {
        if (feat.features.find(f => f.featureId === AccountFeatures.STM_KEYLOCKER_ACCOUNT && f.featureEnabled)) {
          setKeylockerEnabled(true)
          const featureDiv = document.getElementById(AccountFeatures.STM_TRIAL_ACCOUNT)
          if(featureDiv)
            featureDiv.classList.add(styles.disabled)
        }
        if (feat.features.find(f => f.featureId === AccountFeatures.STM_PUBLIC_ISSUANCE && f.featureEnabled)) {
          setCertIssuanceEnabled(true)
        }
        const feature = feat.features.find(f => f.featureId === AccountFeatures.STM_TRIAL_ACCOUNT && f.featureEnabled)
        if (feature) {
          setDate(moment.utc(feature.date))
          setShowDatePicker(true)
          const featureDiv = document.getElementById(AccountFeatures.STM_KEYLOCKER_ACCOUNT)
          if(featureDiv)
            featureDiv.classList.add(styles.disabled)
        }
      })
    }
  }, [props.features, editing])

  const saveApplicationFeatures = (e) => {
    const list = featureEnabledList
    list?.map(feat => {
      if (feat.feature_id === AccountFeatures.STM_TRIAL_ACCOUNT) {
        feat.end_date = moment(date)
      }
    })
    e.preventDefault()
    setEditing(false)
    setErrorState(false)
    setDisableSave(false)
    props.enableFeature(appBeingEdited, list, appCodeBeingEdited, props.id).then(() => {
      setTimeout(() => {
        props.setUpdateFeatures(true)
      }, 1000)
    })
    setAppBeingEdited('')
    setFeatureEnabledList([])
  }

  const editApplicationFeatures = (id: string, appCode: string, e?: React.MouseEvent<HTMLButtonElement>) => {
    e ? e.preventDefault() : undefined
    setAppCodeBeingEdited(appCode)
    setAppBeingEdited(id)
    props.setUpdateFeatures(false)
    setEditing(true)
  }

  const cancelEditingApplicationFeatures = (e) => {
    e.preventDefault()
    setErrorState(false)
    setEditing(false)
    setDisableSave(false)
    setShowDatePicker(false)
    setAppBeingEdited('')
    setFeatureEnabledList([])
    const featureDivTrial = document.getElementById(AccountFeatures.STM_TRIAL_ACCOUNT)
    const featureDivKeylocker = document.getElementById(AccountFeatures.STM_KEYLOCKER_ACCOUNT)
    if (featureDivTrial && featureDivKeylocker) {
      featureDivTrial.classList.remove(styles.disabled)
      featureDivKeylocker.classList.remove(styles.disabled)
    }

  }

  const addToList = useCallback((checked, id) : any=> {
    let list = featureEnabledList
    if (list) {
      const valExists = list.find(val => val.feature_id === id)
      if(valExists) {
        list.map(val => {
          if(val.feature_id === id) {
            return val.enabled = checked
          }
        })
      } else {
        list.push({
          feature_id: id,
          enabled: checked
        })
      }
    } else {
      list = [{
        feature_id: id,
        enabled: checked
      }]
    }
    setFeatureEnabledList(list)
    return list
  },[featureEnabledList])

  const switchChange = (checked, e) => {
    setDisableSave(false)
    if(!checked)
      setEnableAllSelected(false)
    if (appCodeBeingEdited == Applications.MPKI) {
      const switchList = switchEnableList
      switchList?.map(val => {
        if(val.key === e.currentTarget.id) {
          val.enabled = checked
        }
      })
      if (switchList?.filter(f => f.enabled).length === switchList?.length)  {
        setEnableAllSelected(true)
      }
      setSwitchEnableList(switchList)
    }

    const list = addToList(checked, e.currentTarget.id)
    if (e.currentTarget.id === AccountFeatures.STM_KEYLOCKER_ACCOUNT) {
      setKeylockerEnabled(checked)
      const featureDiv = document.getElementById(AccountFeatures.STM_TRIAL_ACCOUNT)
      if (checked) {
        /*
          KeyLocker is selected
            -Disable Trial account
            -Show required tag on Public certificate issuance when Public certificate issuance is not selected
        */
        if (list.find(feat => feat.feature_id === AccountFeatures.STM_PUBLIC_ISSUANCE) === undefined && !certIssuanceEnabled) {
          setErrorState(true)
          setDisableSave(true)
        }
        if (featureDiv) {
          featureDiv.classList.add(styles.disabled)
        }
      } else {
        if (featureDiv) {
          setErrorState(false)
          setDisableSave(false)
          featureDiv.classList.remove(styles.disabled)
        }
      }

    }

    if (e.currentTarget.id === AccountFeatures.STM_PUBLIC_ISSUANCE && !checked && keyclockerEnabled) {
      setErrorState(true)
      setDisableSave(true)
    }

    if (e.currentTarget.id === AccountFeatures.STM_TRIAL_ACCOUNT) {
      const featureDiv = document.getElementById(AccountFeatures.STM_KEYLOCKER_ACCOUNT)
      if (checked) {
        /*
        Trial account is selected
          -Disable Kelocker
          -Show date picker
          -disable save button till date is picked
        */
        if (featureDiv) {
          featureDiv.classList.add(styles.disabled)
        }
        setShowDatePicker(true)
        setDisableSave(true)
      } else {
        if (featureDiv) {
          featureDiv.classList.remove(styles.disabled)
        }
        setShowDatePicker(false)
        setDate(undefined)
        setDisableSave(false)
      }
    }

    if (list?.find(feat => feat.feature_id === AccountFeatures.STM_PUBLIC_ISSUANCE && feat.enabled)
      && keyclockerEnabled) {
      setErrorState(false)
      setDisableSave(false)
    }
  }

  const handleDateChange = useCallback(
    (value) => {
      setDisableSave(false)
      setDate(value)
    },
    [setDate]
  )

  const getEnabled = useCallback((id: string): any => {
  if (switchEnableList) {
    const feat = switchEnableList.find(f => f.key === id)
      if (editing && feat) {
          return feat.enabled
      }
  }
  }, [switchEnableList, editing])

  useEffect(() => {
    const array: Array<any> = []
    if (editing) {
      props.features.map(feat => {
        if (feat.features.find(f => f.featureId === AccountFeatures.STM_TRIAL_ACCOUNT && f.featureEnabled)) {
          addToList(true, AccountFeatures.STM_TRIAL_ACCOUNT )
        }
          if (feat.appId === appBeingEdited) {
            feat.features.map(f => {
              array.push({
                key: f.featureId,
                enabled: f.featureEnabled
              })
            })
            setSwitchEnableList(array)
        }
      })
      if (appCodeBeingEdited === Applications.MPKI) {
        const enabled = props.features.map(feat => {
          if (feat.appId === appBeingEdited) {
            return feat.features.filter(f => f.featureEnabled).length === feat.features.length
          }
          }).filter(val => val !== undefined)
        if (enabled[0])  {
          setEnableAllSelected(true)
        }
      }
    }
  },[editing, props.features, appCodeBeingEdited])

  const disabledDate = useCallback(
    (current) => {
      // Can not select days before today and today, or later than the Account end date
      if (props.accountEndDate) {
        return (
          (current && current < moment().endOf('day')) ||
          current > moment(props.accountEndDate)
        )
      }

      return current && current < moment().endOf('day')
    },
    [props.accountEndDate]
  )

  const enableAllChange = (checked) => {
    setEnableAllSelected(checked)
    setDisableSave(false)
    const list = switchEnableList
    let featureList: Array<any> = []
    if (editing) {
        props.features.map(feat => {
          if (feat.appId === appBeingEdited) {
            feat.features.map(f => {
              list?.map(l => {
                if(l.key === f.featureId) {
                  featureList.push({
                    feature_id: l.key,
                    enabled: checked
                  })
                  l.enabled = checked
                }
              })
            })
          }
        })
        setFeatureEnabledList(featureList)
        setSwitchEnableList(list)
    }
  }

  return (
    <section className={styles.features}>
      {props.features.map((feat, key) => {
        return (
          <div key={key} className={cn(styles.feature, editing && appBeingEdited !== feat.appId && styles.disabled)}>
            <div className={styles.header}>
              <p className={styles.appName}>{feat.header}</p>
              {!editing && (props.profile.access_scope != IAccessScope.partner || props.profile.primary_account_id != props.account.id) && (
                <button className={styles.appEdit} onClick={(e) => editApplicationFeatures(feat.appId, feat.appCode , e)}>
                  <FontAwesomeIcon size='lg' icon={faPencil}/>
                </button>
              )}
              {editing && appBeingEdited !== feat.appId  && (props.profile.access_scope != IAccessScope.partner || props.profile.primary_account_id != props.account.id) &&(
                <button className={styles.appEdit} onClick={(e) => editApplicationFeatures(feat.appId, feat.appCode , e)}>
                  <FontAwesomeIcon size='lg' icon={faPencil}/>
                </button>
              )}
            </div>
            {feat.defaultFeatures.length > 0 &&
            <>
              <div key={1} className={cn(styles.includedFeatures, editing && styles.disabled)}>
                <div className={styles.header}>
                  <p className={styles.title}>{feat.appCode === 'device_trust_manager' ? <Translate id='features.devcInclude' /> : <Translate id='features.include' />}</p>
                  <Tag className={styles.tag} type={TagType.ACTIVE}>
                    <Translate id='common.statuses.enabled' />
                  </Tag>
                </div>
                {feat.defaultFeatures.sort((a,b) => (a.featureDisplayOrder > b.featureDisplayOrder) ? 1 : ((b.featureDisplayOrder > a.featureDisplayOrder) ? -1 : 0)).map((defaultFeature) => {
                  return (
                    <div className={styles.includedFeature} key={defaultFeature.featureId}>
                      <p className={styles.name}>{defaultFeature.featureName}</p>
                      <p className={styles.description}>{defaultFeature.featureDetails}</p>
                    </div>
                  )
                })}
              </div>
               <div className={styles.includedFeatureHeader}>
                 <p className={styles.title}>{feat.appCode === 'device_trust_manager' ? <Translate id='features.devcFeatures' /> : <Translate id='features.enrollmentsAndIntegrations'/>}</p>
                 {editing && (
                   <div className={styles.toggle}>
                     <span className={styles.label}>
                       {!enableAllSelected
                         ? <Translate id={'common.form.buttons.enable'}/>
                         : <Translate id={'common.form.buttons.disable'}/>
                       }
                       &nbsp;all
                     </span>
                     <Switch
                      id='enableAll'
                      checkedChildren='On'
                      unCheckedChildren='Off'
                      onChange={enableAllChange}
                      checked={enableAllSelected}
                    />
                   </div>
                 )}
               </div>
              </>
             }
            {feat.features.map((feature) => {
              return (
                <div className={styles.content} key={feature.featureId} id={feature.featureId}>
                  <p className={styles.name}>{feature.featureName}</p>
                  <p className={styles.description}>{feature.featureDetails}</p>
                  {feature.featureAdditionalInformation && editing && (
                    <ul className={styles.additionalInfo}>
                      {feature.featureAdditionalInformation.map((info, index) => {
                        return (
                          <li key={index}>{info}</li>
                        )
                      })}
                    </ul>
                  )}
                  {editing && showDatePicker && feature.featureId === AccountFeatures.STM_TRIAL_ACCOUNT &&
                  <DatePicker
                    label={translate('common.date')}
                    id='trial_account_datepicker'
                    value={date}
                    onChange={handleDateChange}
                    disabledDate={disabledDate}
                  />
                  }
                  {feature.errorMessage && errorState && (
                    <p className={styles.error}>
                      <FontAwesomeIcon icon={faExclamationCircle}/> {feature.errorMessage}
                    </p>
                  )}
                  <div className={styles.action}>
                    {editing && appBeingEdited === feat.appId
                      ?
                      <Switch
                        checkedChildren='On'
                        unCheckedChildren='Off'
                        onClick={() => {setContext(feat.context)}}
                        onChange={switchChange}
                        id={feature.featureId}
                        checked={getEnabled(feature.featureId)}
                        invalid={feature.errorMessage !== '' && errorState}
                      />
                      : <Tag type={feature.featureEnabled? TagType.ACTIVE : TagType.INACTIVE}>
                        <Translate id={`common.statuses.${feature.featureEnabled ? 'enabled' : 'disabled'}`} />
                      </Tag>
                    }
                  </div>
                </div>
              )
            })}
            {editing && appBeingEdited === feat.appId  && (
              <div className={styles.featureActions}>
                <Button buttonType={ButtonType.SECONDARY} onClick={cancelEditingApplicationFeatures}><Translate id={'common.form.buttons.cancelBtn'}/></Button>
                <Button onClick={saveApplicationFeatures} disabled={disableSave}><Translate id={'common.form.buttons.saveBtn'}/></Button>
              </div>
            )}
          </div>
        )
      })}
    </section>
  )
}
export default connect(
  null,
  { enableFeature }
)(Features)