import React, { FormEvent, ReactElement, useCallback, useEffect, useState } from 'react'
import {
  Button,
  ButtonType,
  Form,
  formatPhoneNumberIntl,
  FormFieldSize,
  FormGroup,
  Input,
  InputType,
  Select,
  SubmitButton,
  Tooltip
} from '@digicert/dcone-common-ui'
import { connect } from 'react-redux'
import { IAppState } from '../../../core/store/store'
import { Translate } from 'react-localize-redux'
import { editUser } from '../../../core/actions/Users.actions'
import { fetchProfile } from '../../../core/actions/Profile.actions'
import { initLocalization } from '../../../core/actions/Localization.actions'
import history from '../../../core/history/history'
import { IEditProfileProps } from './EditProfile.types'
import { IAccessScope } from '../../../reducers/Reducers.interfaces'
import Cookies from 'js-cookie'
import { DCONE_LOCALE_COOKIE_NAME, USER_NAME_REGEXP } from '../../../core/utils/constants'
import { BasePathConst } from '../../../configs/Path.configs'
import { isValidEmail, translate } from '../../../shared/helpers/utils'
import { checkIfUsernameExists } from 'public-pages/self-service-user/SelfServiceUser.actions'
import styles from './EditProfile.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'

const EditProfile = (
  {
    fetchProfile,
    checkIfUsernameExists,
    localeList,
    profile,
    editUser,
    initLocalization
  }: IEditProfileProps): ReactElement => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [userName, setUserName] = useState('')
  const [samlUserName, setSamlUserName] = useState('')
  const [oidcUserName, setOidcUserName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [language, setLanguage] = useState('')
  const [nameDuplicateError, setNameDuplicateError] = useState(false)
  const [oneLoginUser, setOneLoginUser] = useState<boolean>(false)
  const [canEditUser, setCanEditUser] = useState<boolean>(true)
  const [aliasPairs, setAliasPairs] = useState<any>([])

  const changeFirstName = useCallback(
    (event) => setFirstName(event.target.value),
    [setFirstName]
  )

  const changeLastName = useCallback(
    (event) => setLastName(event.target.value),
    [setLastName]
  )

  const changeEmail = useCallback(
    (event) => setEmail(event.target.value),
    [setEmail]
  )
  
  const validateEmail = ():string => {
    if(!isValidEmail(email)){
      return translate('manageUser.administrators.validation.invalidEmail') as string
    }
    return ''
  }

  const validateUserName = (): any => {
    if (nameDuplicateError) {
        return translate('notifications.exceptions.USERNAME_EXISTS');
      }
  }

  const changeUserName = useCallback(
    (event) => {
      setUserName(event.target.value)
      checkIfUsernameExists(event.target.value, undefined, 'userName', profile!.id).then((res:boolean) => {
        if (res) {
            setNameDuplicateError(true)
        } else {
            setNameDuplicateError(false)
        }
    })
    },
    [setUserName]
  )

  const changeSamlUserName = useCallback(
    (event) => setSamlUserName(event.target.value),
    [setSamlUserName]
  )

  const changeOidcUserName = useCallback(
      (event) => setOidcUserName(event.target.value),
      [setOidcUserName]
  )

  const changePhoneNumber = useCallback(
    (value) => {
      if (value) {
        setPhoneNumber(value);
      }
    },
    [setPhoneNumber]
  )

  const changeLanguage = (value): void => {
    setLanguage(value)
  };

  const getLocaleOptions = () => {
    return localeList.locales.map((locale) => {
      return {
        label: locale.name,
        value: locale.id
      }
    })
  }

  const onCancel = (event) => {
    event.preventDefault()
    history.goBack()
  }

  const updateProfile = async (event:FormEvent): Promise<void> => {
    event.preventDefault()
    if(profile) {
      const profileData:any = {
        first_name: firstName,
        last_name: lastName,
        user_name: userName,
        email: email,
        phone: formatPhoneNumberIntl(phoneNumber),
        locale: language,
        permissions: profile.permissions,
        accounts: (profile.access_scope === IAccessScope.account || profile.access_scope === IAccessScope.partner) ?
        profile.access_scope === IAccessScope.partner ? profile.accounts.filter(account => account.active && !account.partner_account_id) : profile.accounts.filter(account => account.active) : [],
        access_scope: profile.access_scope,
      }
      if (aliasPairs.length > 0) {
        profileData.aliases = aliasPairs.reduce((acc, curr) => {
          acc[curr.id] = curr.value
          return acc
        }, {})
      }

      await editUser(profile.id, profileData, false)
      Cookies.set(DCONE_LOCALE_COOKIE_NAME, profileData.locale)
      await initLocalization(profileData.locale).then(() => {
        window.location.href = `${BasePathConst}/profile`
      })
    }
  }

  useEffect(() => {
    fetchProfile().then((response: any) => {
      setFirstName(response.first_name)
      setLastName(response.last_name)
      setUserName(response.user_name)
      setEmail(response.email)
      // phone number maybe optional
      setPhoneNumber(response.phone || '')
      setLanguage(response.locale)
      setSamlUserName(response.saml_username)
      setOidcUserName(response.oidc_username)
      setOneLoginUser(response.has_onelogin_id)
      setCanEditUser(profile?.access_scope === IAccessScope.system || !response.primary_account_dta_enabled)
      response.aliases && setAliasPairs(Object.entries(response.aliases).map(([key, value]) => ({ id: key, value: value })))
    })
  }, [])
  const updateAliases = (event, index) => {
    const updatedAliases = [...aliasPairs]
    updatedAliases[index] = { ...updatedAliases[index], [event.target.name]: event.target.value }
    setAliasPairs(updatedAliases)
  }

  const removeAliases = (index) => {
    const updatedAliases = [...aliasPairs]
    updatedAliases.splice(index, 1)
    setAliasPairs(updatedAliases)
  }

  return (
    <section className={styles.editProfile}>
      <h1>
        <Translate id='profile.title'/>
      </h1>

      <Form size={FormFieldSize.L} onSubmit={updateProfile}>
        <FormGroup>
          <Input
            label={<Translate id='common.form.firstName'/>}
            required
            value={firstName}
            errors={{ valueMissing: <Translate id='manageUser.administrators.validation.required'/> }}
            onChange={changeFirstName}
            disabled={oneLoginUser || !canEditUser}
          />

          <Input
            label={<Translate id='common.form.lastName'/>}
            required
            value={lastName}
            errors={{ valueMissing: <Translate id='manageUser.administrators.validation.required'/> }}
            onChange={changeLastName}
            disabled={oneLoginUser || !canEditUser}
          />
        </FormGroup>


        <FormGroup>
          <Input
            label={<Translate id='common.form.stdUsername'/>}
            required
            value={userName}
            errors={{ valueMissing: <Translate id='manageUser.administrators.validation.required'/> }}
            onChange={changeUserName}
            validate={validateUserName}
            disabled={oneLoginUser}
          />
        </FormGroup>

        {samlUserName && (
          <FormGroup>
            <Input
              label={<Translate id='common.form.samlUsername'/>}
              required
              value={samlUserName}
              errors={{ valueMissing: <Translate id='manageUser.administrators.validation.required'/> }}
              onChange={changeSamlUserName}
              disabled={oneLoginUser || !canEditUser}
              readOnly
            />
          </FormGroup>
        )}

        {oidcUserName && (
            <FormGroup>
              <Input
                  label={<Translate id='common.form.oidcUsername'/>}
                  required
                  value={oidcUserName}
                  errors={{ valueMissing: <Translate id='manageUser.administrators.validation.required'/> }}
                  onChange={changeOidcUserName}
                  disabled={oneLoginUser || !canEditUser}
                  readOnly
              />
            </FormGroup>
        )}

        <FormGroup>
          <Input
            label={<Translate id='profile.email'/>}
            type={InputType.EMAIL}
            value={email}
            onChange={changeEmail}
            disabled={oneLoginUser || !canEditUser}
            validate={validateEmail}
          />
        </FormGroup>
        <FormGroup>
          <Input
            label={<Translate id='profile.phone'/>}
            type={InputType.TELEPHONE}
            value={phoneNumber}
            errors={{ valueMissing: <Translate id='manageUser.administrators.validation.required'/> }}
            onChange={changePhoneNumber}
            disabled={oneLoginUser}
          />
        </FormGroup>

        <FormGroup>
          <Select
            getPopupContainer={triggerNode => triggerNode.parentElement}
            label={<Translate id='profile.language'/>}
            options={getLocaleOptions()}
            defaultValue={language}
            disabled={oneLoginUser}
            value={language}
            errors={{ valueMissing: <Translate id='manageUser.administrators.validation.required'/> }}
            onChange={changeLanguage}
          />
        </FormGroup>
        {profile?.access_scope === IAccessScope.system && aliasPairs.length > 0 &&
          <div className={styles.aliases}>
            <h4><Translate id='common.form.aliases'/></h4>
            <p className={styles.subTitle}><Translate id='common.form.aliasesSubtitle'/></p>
            {aliasPairs.map((aliasPair, index) => {
              return (
                <div className={styles.row} key={index}>
                <div className={styles.aliasesGroup}>
                  <Input
                    size={FormFieldSize.L}
                    label={translate('common.form.identifier')}
                    required
                    value={aliasPair.id}
                    onChange={(event) => updateAliases(event, index)}
                    name='id'
                    maxLength={30}
                    pattern={USER_NAME_REGEXP}
                    disabled={false}
                    errors={{
                      valueMissing: translate('common.form.validation.required'),
                      patternMismatch: translate('common.form.validation.invalidIdentifier')
                    }}
                  />
                  <Input
                    label={translate('common.form.value')}
                    required
                    value={aliasPair.value}
                    onChange={(event) => updateAliases(event, index)}
                    pattern={USER_NAME_REGEXP}
                    name='value'
                    disabled={false}
                    maxLength={50}
                    errors={{
                      valueMissing: translate('common.form.validation.required'),
                      patternMismatch: translate('common.form.validation.invalidValue')
                    }}
                  />
                  
                </div>
                <span className={styles.removeIcon}>
                <Tooltip title={<Translate id='common.form.buttons.deleteBtn' />} placement='top'>
                  <FontAwesomeIcon size='lg' icon={faTrashAlt} onClick={() => removeAliases(index)} />
                </Tooltip>
              </span>
              </div>
              )
            })}
          </div>
        }

        <div className={styles.formActions}>
          <Button buttonType={ButtonType.SECONDARY} onClick={onCancel} id='cancel_btn_profile'>
            <Translate id='profile.cancelBtn'/>
          </Button>
          {!oneLoginUser &&
            <SubmitButton id='save_or_update_contact_btn_profile'>
              <Translate id='profile.updateBtn' />
            </SubmitButton>
          }
          
        </div>
      </Form>
    </section>
  )
}

export default connect(
  (state: IAppState) => ({
    profile: state.profile.data,
    isEditing: state.users.isEditing,
    localeList: state.locales
  }),
  { editUser, fetchProfile, initLocalization, checkIfUsernameExists }
)(EditProfile)