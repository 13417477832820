import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Translate } from 'react-localize-redux'
import isEqual from 'fast-deep-equal'
import { formatDate, getEntityStatus, translate } from 'shared/helpers/utils'
import { fetchProfile } from 'core/actions/Profile.actions'
import { withPermissionsHOC } from 'core/hoc/permissionsHoc'
import {
  LIMIT_10,
  PAGE_LEVEL_ERROR_NOTIFICATION,
  PAGE_LEVEL_WARN_NOTIFICATION,
  RouterConfig
} from 'core/utils/constants'
import { IAppState } from 'core/store/store'
import { IFilters, ISort } from 'core/actions/Actions.interfaces'
import { BasePathConst } from 'configs/Path.configs'
import { IAccessScope, ITenant } from 'reducers/Reducers.interfaces'
import { AccountTypes, IStateProps, PropsType } from './Tenants.types'
import { deleteTenants, fetchExpiringAccounts, fetchTenants } from './Tenants.actions'
import history from 'core/history/history'
import {
  Button,
  ButtonDropDown,
  DynamicDateFilter,
  DynamicDateFilterOptionTypes,
  FreeTextFilter,
  PageLevelBanner,
  PageLevelBannerType,
  SelectFilter,
  Table,
  TableColumn,
  TableMetaProps,
  Tag,
  TagType
} from '@digicert/dcone-common-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/pro-light-svg-icons'
import styles from './tenants.page.module.scss'
import moment from 'moment'
import { getItemFromLS, setItemToLS } from 'shared/helpers/LocalStorage'
import { resizeTableBody } from '../../shared/helpers/Table'
import { Link } from 'react-router-dom'

  const TenantsPage = (props: PropsType): ReactElement => {
    const {accessScope, fetchExpiringAccounts, profile, tenants, permissions} = props
   const defaultFilters: any = {
      active: [
        {
          value: 'true',
          func: "eq",
          label: translate('common.statuses.active') as string
        }
      ]
    }

    let metaOnMount: TableMetaProps<ITenant> = {
      pageSize: LIMIT_10,
      currentPage: 1,
      totalItems: 0,
      filters: defaultFilters
    };
    const tableMeta = JSON.parse(getItemFromLS('dcone-table/accounts-table-' + profile?.id) as string)
    if (tableMeta !== null && tableMeta.meta.filters !== undefined && Object.entries(tableMeta.meta.filters).length !== 0) {
      metaOnMount = {
        pageSize: LIMIT_10,
        currentPage: 1,
        totalItems: 0,
        filters: tableMeta.meta.filters
      };
    }

    const [current, setCurrent] = useState(1)
    const [sortedInfo, setSortedInfo] = useState<any>({
      columnKey: 'name',
      order: 'ascend'
    })
    const [tenantIds, setTenantIds] = useState<string[]>([])
    const [meta, setMeta] = useState(metaOnMount)
    const [showPageLevelWarning, setShowPageLevelWarning] = useState(false)
    const [showPageLevelError, setShowPageLevelError] = useState(false)
    const [warningMessage, setWarningMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [showErrorBannerAfterDismiss, setShowErrorBannerAfterDismiss] = useState(true)
    const [showWarnBannerAfterDismiss, setShowWarnBannerAfterDismiss] = useState(true)
    const [loading, setLoading] = useState(true)
    const [filters, setFilters] = useState<any>()

    const onFilter = (filters?: IFilters) => {
      setFilters(filters)
    }


    const parseFilter = (newMeta): any => {
      const tagTypes: any = ['active', 'inactive']
      // format array into structure compatible with onFilter
      if(newMeta.filters){
        const tmpArray: any = Object.getOwnPropertyNames(newMeta.filters).map((key: string) => {
          if (newMeta.filters) {
            const item = newMeta.filters[key]
            if (item) {
              // format end date to work with API
              if (key == 'endDate') {
                if (item[0].value.option == 'days_from_current_date') {
                  return {
                    [key]: [moment.utc().format('YYYY-MM-DD').toString(),  moment.utc().add(item[0].value.value, 'days').format('YYYY-MM-DD').toString()]
                  }
                }
                if (item[0].value.option == 'date_range') {
                  item[0].value.value.map((option) => {
                    if(option.value) {
                      return moment(option.value).format('YYYY-MM-DD').toString()
                    }
                  })
                  return {
                    [key]: [item[0].value.value.map(val => moment(val).format('YYYY-MM-DD').toString())]
                  }
                }
              }
              if (key === 'partner_account_name') {
                return {
                  ['partnerAccountNameLike']: newMeta.filters[key].map((option) => {
                    return option.value
                  })
                }
              }
              // format tagType/status, API expects active of boolean type where table filter expects string
              if (tagTypes.includes(key)) {
                if (newMeta.filters?.active) {
                  const values = new Set(
                    newMeta.filters.active.map((option) => option.value)
                  );
                  if (values.size >= 2) {
                    return {}
                  }
                }
                return {
                  [key]: item[0].value == 'true'
                }
              }

              else {
                return {
                  [key]: newMeta.filters[key].map((option) => {
                    return option.value
                  })
                }
              }
            }
          }
        });
        // comma separate remaining properties from array
        const filter = Object.assign({}, ...tmpArray)
        onFilter(filter)
        return filter

      }

      // clear filters
      else {
        onFilter(Object.assign({}, {}))
      }
    }

    const fetchTenants = useCallback((metaProps?: TableMetaProps<ITenant>) => {
        const params: ISort = { limit: metaProps ? metaProps.pageSize : 10, offset: metaProps ? metaProps.pageSize * (metaProps.currentPage - 1) : 0 }
        if (sortedInfo.order && sortedInfo.columnKey) {
          params.sort = [{
            columnKey: `${sortedInfo.columnKey}:${sortedInfo.order === 'descend' ? 'desc' : 'asc'}`
          }]
        }
      props.fetchTenants(params, metaProps ? parseFilter(metaProps) : filters).then((response: any) => {
        if (response && metaProps) {
          setMeta({ ...metaProps, ...{totalItems: response.total} });
          setLoading(false)
        }
      })
    }, [filters])


    const onSelectionChange = (tenantIds: string[] | number[]): void => {
      setTenantIds(tenantIds as string[])
    }

    useEffect(() => {
      if (getItemFromLS(PAGE_LEVEL_ERROR_NOTIFICATION) != null) {
        setShowErrorBannerAfterDismiss(false)
      }
      if (getItemFromLS(PAGE_LEVEL_WARN_NOTIFICATION) != null) {
        setShowWarnBannerAfterDismiss(false)
      }
      if(accessScope === IAccessScope.system) {
        fetchExpiringAccounts().then((response) => {
          const numberOfAccountsExpiring = new Map(Object.entries(response));
          if (numberOfAccountsExpiring.get('30')) {
            setErrorMessage( <Translate id={'common.form.accountExpiryNotification.expiringInDays'} data={{ amount: numberOfAccountsExpiring.get('30') as string, day: '30' }} /> as unknown as string)
            setShowPageLevelError(true)
          }
          if (numberOfAccountsExpiring.get('60')) {
            setWarningMessage(<Translate id={'common.form.accountExpiryNotification.expiringInDays'} data={{ amount: numberOfAccountsExpiring.get('60') as string, day: '60' }} /> as unknown as string)
            setShowPageLevelWarning(true)
          }
        })
      }
      fetchTenants(meta)
      const tableElement = document.getElementsByClassName('resizable-table-body')[0] as HTMLElement;
      if(tableElement) {
        resizeTableBody(tableElement)
        window.addEventListener('resize', () => resizeTableBody(tableElement))
      }
    }, [])


  const populateTable = (day: number) => {
    const filter: TableMetaProps<ITenant> = {
      pageSize: LIMIT_10,
      currentPage: 1,
      totalItems: 0,
      filters: {
        active: [
          {
            value: 'true',
            func: "eq",
            label: translate('common.statuses.active') as string
          }
        ],
        endDate: [
          {
            value: {
              option: "days_from_current_date",
              type: "numberInput",
              value: day
            },
            func: "eq",
            label: day.toString() + translate('common.table.filters.daysFrom') as string
          }
        ]
      }
    };
    setMeta(filter)
    parseFilter(filter)
    fetchTenants(filter)
  }

  const  onDismissErrorBanner = () => {
    setShowErrorBannerAfterDismiss(false)
    setItemToLS(PAGE_LEVEL_ERROR_NOTIFICATION, 'false')
  }

  const onDismissWarnBanner = () => {
    setShowWarnBannerAfterDismiss(false)
    setItemToLS(PAGE_LEVEL_WARN_NOTIFICATION, 'false')
  }

  const getPartnerAccount = (partnerAccountName) => {
    if (partnerAccountName) {
      const lastIndex = partnerAccountName.lastIndexOf('-')
      const [accountName, identifier] = [partnerAccountName.slice(0,lastIndex),partnerAccountName.slice(lastIndex+1)]
      return <>
      <div>{accountName}</div>
      <div className={styles.identifier}>{identifier}</div>
      </>
    }
    else {
      return <Translate id='accounts.table.system' />
    }
  }

  const getDynamicDateFilterOptions  = (): any => [
    {
      value: 'days_from_current_date',
      label: translate('common.table.filters.daysFromCurrentDate') as string,
      type: DynamicDateFilterOptionTypes.NUMBER_INPUT,
      units: 'days',
      applyButtonLabel: translate('common.buttons.apply') as string
    },
    {
      value: 'date_range',
      label: translate('common.table.filters.dateRange') as string,
      type: DynamicDateFilterOptionTypes.DATE_RANGE
    }
  ];

  const getColumns = (): TableColumn<ITenant>[] => {
    const cols: TableColumn<ITenant>[] =
    [
      {
        key: 'name',
        displayRowActions: true,
        title: translate('common.form.name') as string,
        sorter: true,
        renderFilter: function BasicStringFilter() {
          return (
            <FreeTextFilter<ITenant>
              label='Name'
              columnKey='name'
            />
          );
        },
        render: tenant => {
          if (tenant.active || accessScope === IAccessScope.system) {
            return (
              <Link to={RouterConfig.accounts.details(tenant.id)}>
                {tenant.name}
              </Link>
            )
          } else {
            return (<>{tenant.name}</>)
          }
        }
      },
      {
        key: 'active',
        title: translate('common.status') as string,
        renderFilter: function BasicStringFilter() {
          return (
            <SelectFilter<ITenant, string>
              items={[
                {
                  value: 'true',
                  label: 'active',
                  func: 'eq'
                },
                {
                  value: 'false',
                  label: 'inactive',
                  func: 'eq'
                }
              ]}
              columnKey='active'
              label={translate('common.status') as string}
              minSearchLength={1}
            />
          );
        },
        render: tenant => (
          <Tag type={tenant.active ? TagType.ACTIVE : TagType.INACTIVE}>
            <Translate id={`common.statuses.${getEntityStatus(tenant.active)}`} />
          </Tag>
        ),
      },
      {
        key: 'friendly_identifier',
        title: translate('accounts.table.id') as string,
        renderFilter: function BasicStringFilter() {
          return (
            <FreeTextFilter<ITenant>
              label='ID'
              columnKey='friendly_identifier'
            />
          );
        }
      },
      {
        key: 'from',
        title: translate('accounts.table.startDate') as string,
        render: tenant => (
          <>
            {formatDate(tenant.service_period.from, profile?.date_format as string)}
          </>
        ),
      },
      {
        key: 'endDate',
        title: translate('accounts.table.endDate') as string,
        render: tenant => (
          <>
            {formatDate(tenant.service_period.to, profile?.date_format as string)}
          </>
        ),
        renderFilter: (popupContainer) => {
          return (
            <DynamicDateFilter
              label={translate('accounts.table.endDate') as string}
              columnKey='endDate'
              popupContainer={popupContainer}
              items={getDynamicDateFilterOptions()}
            />
          );
        }
      }
    ]

    if (props.features.AM_enable_partner_scope && (accessScope === IAccessScope.system || accessScope === IAccessScope.partner)) {
      cols.push({
        key: 'type',
        title: translate('common.table.columns.type') as string,
        sorter: true,
        hidden: false,
        renderFilter: () => {
          return (
            <SelectFilter<ITenant, string>
              items={[
                {
                  value: AccountTypes.STANDARD,
                  label: translate('manageUser.administrators.standardAccount') as string,
                  func: 'eq'
                },
                {
                  value: AccountTypes.PARTNER,
                  label: translate('manageUser.administrators.partnerAccount') as string,
                  func: 'eq'
                }
              ]}
              columnKey='type'
              label={translate('common.table.columns.type') as string}
              minSearchLength={1}
            />
          );
        },
        render: tenant => (
            <Translate id={tenant.type === AccountTypes.PARTNER ? `manageUser.administrators.partnerAccount`: `manageUser.administrators.standardAccount`} />
        ),
      },
      {
        key: 'partner_account_name',
        title: translate('common.table.columns.partnerAccount') as string,
        sorter: true,
        hidden: false,
        renderFilter: function BasicStringFilter() {
          return (
            <FreeTextFilter<ITenant>
              label={translate('common.table.columns.partnerAccount') as string}
              columnKey='partner_account_name'
            />
          );
        },
        render: tenant => getPartnerAccount(tenant?.partner_account_name)
      }
    )
    }

    if (props.features.AM_show_federated_info_columns) {
      cols.push({
        key: 'federation_status',
        title: translate('common.federationStatus') as string,
        render: tenant => (
          tenant.federation_status ?
          <Translate id={`common.statuses.${tenant.federation_status}`} /> : <></>
        ),
        hidden: false,
        sorter: false
      })
      cols.push({
        key: 'federated_account_id',
        title: translate('common.federatedAccountId') as string,
        render: tenant => (
          <>
            {tenant.federated_account_id}
          </>
        ),
        hidden: false,
        sorter: false
      })
    }

    return cols
  }


  const onEditTenant = (item) => {
    item.has_onelogin_id
      ? window.open(profile?.onelogin_url + RouterConfig.oneloginAccount.editOneloginAccount(item.federated_account_id), '_blank')
      : history.push(`${BasePathConst}/accounts/${item}/edit`)
  }

  const onTableChange = (newMeta:TableMetaProps<ITenant>): void => {
    let newSortedInfo = {
      columnKey: newMeta.sort?.columnKey,
      order: newMeta.sort?.order
    }

    if (newSortedInfo.columnKey === undefined && newSortedInfo.order === undefined) {
      newSortedInfo = {
        columnKey: 'name',
        order: 'ascend'
      }
    }

    if (!isEqual(newSortedInfo, sortedInfo)) {
      // if sort go back to page 1
      setCurrent(newMeta.currentPage = 1)
      setSortedInfo({
        columnKey: newMeta.sort?.columnKey,
        order: newMeta.sort?.order
      })
    }

    if (!isEqual(newMeta.currentPage, current)) {
      setCurrent(newMeta.currentPage)
    }

    if(!isEqual(newMeta.filters, meta.filters)) {
      // if filter go back to page 1
      setCurrent(newMeta.currentPage = 1)

      // formats array into structure compatible with onFilter
      parseFilter(newMeta)
    }
    setMeta(newMeta)
    fetchTenants(newMeta)
  }

  const getRowActions = (item) => {
    const canManageAccount = permissions.canManageAccount && (accessScope === IAccessScope.system
    || (accessScope === IAccessScope.partner && item.partner_account_id === profile?.primary_account_id))
    return [
      {
        title: item.has_onelogin_id ? translate('createAccount.olEditAccountError') as string : translate('accounts.editAccount') as string,
        isVisible: canManageAccount,
        onClick: onEditTenant,
        icon: <FontAwesomeIcon icon={faPencil}/>
      }
    ]
  }

  const navigateToCreateAccount = () => {
    history.push(RouterConfig.accounts.createAccount)
  }

    const canCreateCustomer =
      permissions.canManageAccount &&
      permissions.canManageOrganization &&
      permissions.canManagePermission &&
      (accessScope === IAccessScope.system || accessScope === IAccessScope.partner)

    return (
      <section className={styles.tenants}>
        <header className={styles.header}>
          <h1><Translate id='common.form.accounts'/></h1>
        </header>
        {props.features.AM_enable_partner_scope && canCreateCustomer && accessScope === IAccessScope.system && <div className={styles.actions}>
        <ButtonDropDown
          label={translate('businessUnits.wizardCreateBtn')}
          trigger={['click']}
          items={[
            {
              key: 'create-standard-account',
              label: translate('manageUser.administrators.standardAccount'),
              onClick: () => {
                history.push({ pathname: RouterConfig.accounts.createAccount, state: { isPartnerAccount: false } })
              }
            },
            {
              key: 'create-partner-account',
              label: translate('manageUser.administrators.partnerAccount'),
              onClick: () => {
                history.push({ pathname: RouterConfig.accounts.createPartnerAccount, state: { isPartnerAccount: true } })
              }
            }
          ]}
        />
      </div>}
      {canCreateCustomer && (accessScope === IAccessScope.partner || !props.features.AM_enable_partner_scope) && <div className={styles.actions}>
        <Button onClick={navigateToCreateAccount} role='link'>
          <Translate id='businessUnits.wizardCreateBtn' />
        </Button>
      </div>}
        {showPageLevelError && showErrorBannerAfterDismiss &&
          <div className={styles.actions}>
            <PageLevelBanner
              bannerType={PageLevelBannerType.ERROR}
              description={<a onClick={() => populateTable(30)}>{errorMessage}</a>}
              showDismissIcon
              onDismiss={onDismissErrorBanner}
            />
          </div>
        }
        {showPageLevelWarning && showWarnBannerAfterDismiss &&
          <div className={styles.actions}>
            <PageLevelBanner
              bannerType={PageLevelBannerType.WARNING}
              description={<a onClick={() => populateTable(60)}>{warningMessage}</a>}
              showDismissIcon
              onDismiss={onDismissWarnBanner}
            />
          </div>
        }

        <Table
          className={styles.table + ' ' +  'resizable-table-body'}
          columns={getColumns()}
          items={tenants}
          getRowKey={tenant => tenant.id}
          getActions={(item) => getRowActions(item)}
          actionsButtonsQuantity={1}
          meta={meta}
          onChange={onTableChange}
          selectedRowKeys={tenantIds}
          onRowSelect={onSelectionChange}
          disableCheckBoxes
          saveDataName={'accounts-table-' + profile?.id}
          loading={loading}
        />
      </section>
    )

}

export default connect(
  (state: IAppState): IStateProps => {
    return {
      tenants: state.tenants.items, // all accounts
      isLoading: state.tenants.isLoading,
      accessScope: state.profile.data?.access_scope,
      profileTenants: state.profile.data?.accounts, // accounts logged in user has access too
      profile: state.profile.data,
      features: state.features
    }
  },
  { fetchTenants, deleteTenants, fetchProfile, fetchExpiringAccounts }
)(withPermissionsHOC(TenantsPage, ['canViewAccount']))
